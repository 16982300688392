import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import he from 'he';
import Layout from '../../layouts';
import Hero from '../../components/Hero';
import Info from '../../components/Info';
import Interstitial from '../../components/Interstitial';
import Body from '../../components/Body';
import SEO from '../../components/seo/SEO';
import { pageTitle, randomNumbersArray } from '../../utils/helpers';
function People(props) {
  const [state, setState] = useState({
    people:{}
  });

  useEffect(() => {
    let team = props.data.allWordpressWpFirewoodTeam;
    let totalPeople = team.totalCount;
    let totalTiles = 48;
    let randomPeople = randomNumbersArray(totalPeople);
    let randomTiles = randomNumbersArray(totalTiles);
    let currentPerson = -1;
    let currentTile = -1;
    let timer = undefined;
    createPeople();

    // Get team member details and push to state
    function createPeople() {
      // Get copy of current people
      const statePeople =  state.people;

      for (let i = 0; i < totalTiles; i++) {
        let random = randomPeople[getNextPerson()];
        // Update individual tile object
        statePeople[i] = createPerson(random);
        // Update state
        setState({
          people: statePeople
        });
      }
      startTimer();
    };

    function getNextPerson() {
      let current = currentPerson;
      current = current + 1;
      current = current > totalPeople - 1 ? 0 : current;
      currentPerson = current;
      return currentPerson;
    };

    function createPerson(key) {
      let node = team.edges[key].node;
      let img = (node.acf.thumbnail.localFile) ? node.acf.thumbnail.localFile.childCloudinaryAsset.fluid : node.acf.picture.localFile.childCloudinaryAsset.fluid;
      let title = he.decode(node.title);
      // Update individual tile object
      return { img: img, title: title };
    };

    // Update displayed team members at a set interval
    function startTimer() {
      const handleInterval = () => {
        setRandomPerson();
      };
      timer = setInterval(handleInterval, 1500);
    };

    function setRandomPerson() {
      // Get copy of current people
      const statePeople = state.people;
      let randomPerson = randomPeople[getNextPerson()];
      let randomTile = randomTiles[getNextTile()];
      statePeople[randomTile] = createPerson(randomPerson);
      // Update state
      setState({
        people: statePeople
      });
    }

    function getNextTile() {
      let current = currentTile;
      current = current + 1;
      current = current > totalTiles - 1 ? 0 : current;
      currentTile = current;
      return currentTile;
    };

    return () => {
      clearInterval(timer);
    }
  }, [props, state.people]);

  function renderPerson(key) {
    let img = state.people[key].img;
    let title = state.people[key].title;
    return (
      <TransitionGroup key={key} component="div" className="person-container">
        <CSSTransition
          classNames="person"
          key={title}
          timeout={{ enter: 1000, exit: 2000 }}
        >
          <div className="person">
            <div className="overlay" />
            <p>{title}</p>
            <Img fluid={img} alt={title} className="gatsby-image-relative" />
            <div className="bg" />
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  };

 
  const currentPage = props.data.wordpressPage;
  const site = props.data.site.siteMetadata;

  const currentPageTitle = currentPage.yoast_meta.yoast_wpseo_title
    ? currentPage.yoast_meta.yoast_wpseo_title
    : pageTitle({
        site: site.title,
        page: currentPage
      });
    
  const personIds = Object.keys(state.people);
  return (
    <Layout>
      <Helmet bodyAttributes={{ id: `page-${currentPage.slug}` }}>
        <title>{currentPageTitle}</title>
        {currentPage.acf.seo_page_description && (
          <meta
            name="description"
            content={currentPage.acf.seo_page_description}
          />
        )}
      </Helmet>
      <SEO node={currentPage} site={site} />
      <Hero node={currentPage.acf.content_page[0]} />
      <div id="people">{personIds.map(renderPerson)}</div>
      <Info node={currentPage.acf.content_page[1]} />
      <Interstitial node={currentPage.acf.content_page[2]} />
      <Body node={currentPage.acf.content_page[3]}  />
    </Layout>
  );
  
}

export default People;

export const pageQuery = graphql`
  {
    wordpressPage(title: { eq: "Our Culture" }) {
      title
      slug
      ...Hero
      ...Info
      ...Interstitial
      ...Body
      ...SEO
    }
    allWordpressWpFirewoodTeam {
      totalCount
      edges {
        node {
          title
          acf {
            picture {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 200, transformations: "h_200,c_lfill") {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
            thumbnail {
              localFile {
                childCloudinaryAsset {
                  fluid(maxWidth: 200, transformations: "h_200,c_lfill") {
                    ...CloudinaryAssetFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    site{
      siteMetadata {
        title
      }
    }
  }
`;
